<script>
import { electronicsDisplay, dianziyouyiLogin, dianziyouyiLoginV1 } from '@/api/home';
export default {
    name: 'ThirdpartyList',
    components:{
        Header: () => import('components/base/Header'),
        'mt-actionsheet': app.Mint.Actionsheet,
        'mt-search': app.Mint.Search,
    },
    data() {
        return {
            header:{
                mainTitle: "",
            },
            searchValue:'',
            sheetVisible: false,
            currentOption: getWord('all_games'),
            actions:[
                {
                    name: getWord('all_games'),
                    method : this.clickOption
                },
            //     {
            //         name: '牌桌',
            //         method : this.clickOption
            //     },
            //     {
            //         name: '电子',
            //         method : this.clickOption
            //     },
            //     {
            //         name: '捕鱼', 
            //         method : this.clickOption
            //     },
            //     {
            //         name: '街机', 
            //         method : this.clickOption
            //     }
            ],
            list:[
                {
                    img:'https://uploadfile.bizhizu.cn/up/83/b9/5e/83b95e6c601123282b05c94e3cb603dd.jpg',
                    title:'游戏1',
                    type:'老虎机',
                    link:'//www.baidu.com',
                },
                {
                    img:'http://d.paper.i4.cn/max/2016/05/04/10/1462328790056_613256.jpg',
                    title:'游戏2',
                    type:'桌面游戏',
                    link:'//www.163.com',
                },
                {
                    img:'http://wlcreative.com/blog/wp-content/uploads/2013/08/Kowloon-panorama.jpg',
                    title:'棋牌棋牌棋牌棋牌',
                    type:'柜台游戏',
                    link:'//www.163.com',
                },
                {
                    img:'https://t2.hddhhn.com/uploads/tu/201710/9999/09a8ff8820.jpg',
                    title:'游戏3',
                    type:'老虎机',
                    link:'//www.sina.com.cn',
                },
            ],
            gameCompany:null,
            gameCompanyID:null,
            currentList:null,
            gameTypes:[],

            loading_data2: false,
            page: 1,
            pageSize: 20,
            totalPage: null,
        }
    },
    props: [
        
    ],
    inject:[
        
    ],
    methods: {
        openOption(){
            this.sheetVisible = true;
        },
        clickOption(data){
            this.currentOption=data.name;
        },
        goto(item){
            if (item.url) {
                window.location.href = url
            }else {
                var params={};
                switch(this.gameCompany) {
                    case 'cq':
                        params={
                            game_code:item.gamecode,
                            type:'mobile'
                        }
                        break;
                    case 'mg':
                        params={
                            item_id:item.item_id,
                            app_id:item.gamecode
                        }
                        break;
                    case 'gg':
                        params={
                            type:1,
                            game_code:item.gamecode
                        }
                        break;
                    default:
                        item.img=null;
                        break;
                }
                if (this.gameCompany==='jdb') {
                    dianziyouyiLoginV1(this.gameCompany, 'mobile', item.gametype, item.gamecode).then(result=>{
                        if (result.data.code === "SUCCESS") {
                            window.location.href = result.data.result.url    
                        }
                    })
                }else {
                    dianziyouyiLogin(this.$route.query.from,params).then(result=>{
                        if (result.data.code === "SUCCESS") {
                            window.location.href = result.data.result.url    
                        }
                    })
                }
            }
        },
        //分页
        loadMore_data() {
            console.log('load')
            if (this.page >= this.totalPage) {
                return false;
            } else {
                this.page++;
                this.loadData();
            }
        },
        loadData() {
            var that=this;

            electronicsDisplay(this.gameCompanyID, this.page, this.pageSize).then(result=>{
                if (result.data.code === "SUCCESS") {
                    result.data.result.list.forEach(item=>{
                        switch(this.gameCompany) {
                            case 'cq':
                                item.img="//cpwap."+process.env.VUE_APP_PROD_DOMAIN+"/"+(this._TEMPLATE==='template-3'?'template-2':this._TEMPLATE)+"/dianziyouyi/"+this.gameCompany+"/"+item.gamecode+".png";
                                break;
                            case 'mg':
                                item.img="//cpwap."+process.env.VUE_APP_PROD_DOMAIN+"/"+'template-2'+"/dianziyouyi/"+this.gameCompany+"/EN/"+item.image;
                                break;
                            case 'gg':
                                item.img="//cpwap."+process.env.VUE_APP_PROD_DOMAIN+"/"+this._TEMPLATE+'/dianziyouyi/gg/'+item.image;
                                break;
                            case 'jdb':
                                item.img="//cppcweb."+process.env.VUE_APP_PROD_DOMAIN+"/"+'template-1'+"/dianziyouyi/"+this.gameCompany+"/cn/"+item.image.substring(item.image.lastIndexOf('/')+1).split('_')[0]+'.jpg';
                                break;
                            default:
                                item.img=null;
                                break;
                        }
                        var related=this.gameTypes.filter(_item=>{
                            return _item===item.gametype
                        })
                        if (related.length===0) {
                            this.gameTypes.push(item.gametype)
                            this.actions.push({
                                name: item.gametype, 
                                method : this.clickOption
                            })
                        }
                    })
                    that.list=that.list.concat(result.data.result.list);
                    this.currentList=this.list;
                    this.totalPage = result.data.result.totalPage;
                }
            })

            this.loading_data2 = false;
        },
    },
    mounted() {
        this.header.mainTitle=this.$route.query.mainTitle;
        this.gameCompany=this.$route.query.from.split('Casino')[0].toLowerCase();
        switch(this.gameCompany) {
            case 'cq':
                this.gameCompanyID=12;
                break;
            case 'gg':
                this.gameCompanyID=14;
                break;
            case 'mg':
                this.gameCompanyID=17;
                break;
            case 'sb':
                this.gameCompanyID=19;
                break;
            case 'jdb':
                this.gameCompanyID=21;
                break;
            default:
                this.gameCompanyID=-1;
                break;
        }
        electronicsDisplay(this.gameCompanyID).then(result=>{
            if (result.data.code === "SUCCESS") {
                this.list=result.data.result.list;
                this.list.forEach(item=>{
                    switch(this.gameCompany) {
                        case 'cq':
                            item.img="//cpwap."+process.env.VUE_APP_PROD_DOMAIN+"/"+(this._TEMPLATE==='template-3'?'template-2':this._TEMPLATE)+"/dianziyouyi/"+this.gameCompany+"/"+item.gamecode+".png";
                            break;
                        case 'mg':
                            item.img="//cpwap."+process.env.VUE_APP_PROD_DOMAIN+"/"+'template-2'+"/dianziyouyi/"+this.gameCompany+"/ZH/"+item.image;
                            break;
                        case 'gg':
                            item.img=require("@@/assets/images/dianziyouyi/gg/ZH/"+item.image);
                            break;
                        case 'jdb':
                            item.img="//cppcweb."+process.env.VUE_APP_PROD_DOMAIN+"/"+'template-1'+"/dianziyouyi/"+this.gameCompany+"/cn/"+item.image.substring(item.image.lastIndexOf('/')+1).split('_')[0]+'.jpg';
                            break;
                        default:
                            item.img=null;
                            break;
                    }
                    var related=this.gameTypes.filter(_item=>{
                        return _item===item.gametype
                    })
                    if (related.length===0) {
                        this.gameTypes.push(item.gametype)
                        this.actions.push({
                            name: item.gametype, 
                            method : this.clickOption
                        })
                    }                    
                })
                this.currentList=this.list;
                this.totalPage = result.data.result.totalPage;
            }
        })
    },
    computed:{
        
    },
    watch:{
        searchValue(current,previous){
            if (current==='') {
                this.currentList=this.list;
            }
            else if (current!==previous) {
                console.log('Uncle Sam is watching you.')
                this.currentList=this.list.filter(item=>{
                    return item.gamename.indexOf(current)>-1
                })
                if (this.currentOption!==getWord('all_games')) {
                    this.currentList=this.currentList.filter(item=>{
                        return item.gametype===this.currentOption
                    })
                }
            }
        }
    },
    directives: {
        'infinite-scroll': app.Mint.InfiniteScroll,
    },
    beforeDestroy(){
        
    }
};
</script>
<template>    
    <section id="thirdparty-list" ref="gameList" :class="_TEMPLATE">
        <Header
            :mainTitle="header.mainTitle"
            :backHome=true
        />
        <div class="inner">
            <div class="filter">
                <mt-search v-model="searchValue" />
                <div class="emulate-option">
                    <span @click=openOption>{{currentOption}}</span>
                    <i class="iconfont icon-back"></i>
                </div>                
            </div>
            <ul v-infinite-scroll="loadMore_data" infinite-scroll-disabled="loading_data2" infinite-scroll-distance="50">
                <li v-for="item in currentList">
                    <div class="img" @click.stop='goto(item)'>
                        <template v-if="_TEMPLATE==='template-3'">
                        <span>
                            <img :src="item.img" />
                        </span>
                        </template>
                        <template v-if="_TEMPLATE==='template-1' || _TEMPLATE==='template-2'">
                            <img :src="item.img" />
                        </template>
                    </div>
                    <h4>
                        {{item.gamename}}
                        <span>{{item.gametype}}</span>
                    </h4>
                </li>
            </ul>
        </div>
        <mt-actionsheet :actions="actions" v-model="sheetVisible" />
    </section>
</template>
<style lang='scss' scoped>
@import "@@/assets/style/_variables";
    section#thirdparty-list {
        background-color: #F5F5F9;
        height: 100%;
        overflow: auto;

        &.template-2,
        &.template-3 {

            ul {

                li {

                    .img {
                        display: flex;
                        align-items: center;
                        background-size: cover;
                        background-image: url('/static/assets/images/home/bg/thirdparty-list-template-2.png');
                    }
                }
            }
        }

        &.template-3 {

            ul {

                li {

                    h4 {
                        color:#EC2829;
                    }

                    .img {

                        span {
                            width: 1.4rem;
                            height: 1.4rem;
                            margin: 0 auto;
                            overflow: hidden;
                            box-shadow: 1px 1px 10px 0px rgba(0,0,0,.5);

                            img {
                                object-fit: unset;
                                height: 100%;
                                /*margin: 0 -0.25rem;*/
                                max-width: none;
                                max-height: none;
                            }
                        }
                    }
                }
            }

            .filter {

                .emulate-option {
                    color:#EC2829;
                    border-color:#EC2829;
                }

                .mint-search {

                    /deep/ .mint-searchbar {
                        border-color:#EC2829;
                        border-width:.5px;

                        .mint-searchbar-inner {
                            background-color: transparent;

                            .mint-searchbar-core {
                                background-color: transparent;
                            }
                        }
                    }
                }
            }
        }

        img {
            max-width: 100%;
            max-height: 100%;
        }

        .filter {
            display: flex;
            padding: .25rem;
            background-color: #ffffff;

            .mint-search {
                height: auto;
                flex-grow: 1;

                /deep/ .mint-searchbar {
                    padding: 0;
                    background-color: transparent;
                    border: 1px solid #dddddd;

                    .mint-searchbar-inner {
                        padding: 0 .25rem;

                        .mintui-search {
                            font-size: .3rem;
                        }

                        .mint-searchbar-core {
                            padding:0 .1rem;
                        }
                    }

                    .mint-searchbar-cancel {
                        margin-left: 0;
                        font-family: "iconfont" !important;
                        font-size: 0;
                        font-style: normal;
                        -webkit-font-smoothing: antialiased;
                        -moz-osx-font-smoothing: grayscale;
                        text-align: center;
                        margin-right: .25rem;

                        &:before {
                            content: "\e6d8";
                            font-size: .3rem;
                            background-color: #cccccc;
                            color: #ffffff;
                            border-radius: 100%;
                            padding: 0.025rem .05rem;
                        }
                    }
                }
            }

            .emulate-option {
                color: $TEMPLATE2-theme-color;
                font-size: .3rem;
                border: 1px solid $TEMPLATE2-theme-color;
                display: flex;
                align-items: center;
                padding: 0 .1rem;
                margin-left: .25rem;

                span {
                    white-space: nowrap;
                }

                .icon-back {
                    margin-left: .1rem;
                    display: inline-block;
                    transform: rotateZ(-90deg);
                }
            }
        }

        ul {
            display: flex;
            flex-wrap: wrap;
            padding: .25rem;
            justify-content: space-between;

            li {
                width: 48%;
                list-style-type: none;
                background-color: #ffffff;
                margin-bottom: .25rem;

                .img {
                    height: 1.9rem;

                    img {
                        height: 80%;
                        object-fit: contain;
                        margin: 0 auto;
                        display: block;
                    }
                }                

                h4 {
                    display: flex;
                    justify-content: space-between;
                    padding: .1rem;
                    font-size: .26rem;
                    color: $TEMPLATE2-theme-color;
                    font-weight: normal;
                    align-items: center;

                    span {
                        font-size: .18rem;
                        color: #CCCCCC;
                    }
                }
            }
        }
    }
</style>